import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FaYoutube } from 'react-icons/fa'
import { PortableText } from '@portabletext/react'
import Showcase from '../components/Showcase'
import InfoBlock from '../components/InfoBlock'
import Statistics from '../components/Statistics'
import Footer from '../components/Layout/Footer'

// import * as styles from '../styles/pages/what-we-do-page.module.scss'

export const Head = () => <title>What We Do | GROWoneAFRICA&trade;</title>

const WhatWeDoPage = ({ data }) => {
  const {
    bannerImage,
    clubModelSection,
    peaceOfMindSection,
    growingResponsiblySection
  } = data.sanityWhatWeDoPage

  return (
    <>
      <main>
        <Showcase image={bannerImage.asset} mainText={data.sanityWhatWeDoPage.mainText} subText={data.sanityWhatWeDoPage.subText} textVisible={data.sanityWhatWeDoPage.bannerTextVisible}/> {/* data.showcaseImage */}
        <section id="next">
          {/* THE GOA CLUB MODEL */}
          <InfoBlock
            featuredImage={
              <GatsbyImage image={getImage(clubModelSection.featuredImage.asset)} alt="" />
              // <div className={styles.imageCluster}>
              //   <StaticImage src="../images/what-we-do-page/greenhouse-grow-looking-in.jpg" alt="" />
              //   <StaticImage src="../images/what-we-do-page/leaves-with-hand-holding.jpg" alt="" />
              //   <StaticImage src="../images/what-we-do-page/bud-super-close-up.jpg" alt="" />
              // </div>
            }
          >
            <h2>The GOA Club Model</h2>
            <PortableText value={clubModelSection._rawContent} />
            {/* <Link to="./#" className='btn-primary mt-auto'>Legislative Links</Link> */}
            <a href={data.sanityCompanyInfo.npaVideoLink} target="_blank" rel="noreferrer" className='btn-primary'>NPA Video <FaYoutube /></a>            
          </InfoBlock>
          {/* PEACE OF MIND */}
          <InfoBlock
            featuredImage={
              <GatsbyImage image={getImage(peaceOfMindSection.featuredImage.asset)} alt="" />
            }
            reversed
          >
            <h2>Peace of Mind</h2>
            <PortableText value={peaceOfMindSection._rawContent} />
            {/* Lab protocol link will come here */}
          </InfoBlock>
          {/* GROWING RESPONSIBLY */}
          <InfoBlock
            featuredImage={
              <GatsbyImage image={getImage(growingResponsiblySection.featuredImage.asset)} alt="" />
            }
          >
            <h2>Growing Responsibly</h2>
            <PortableText value={growingResponsiblySection._rawContent} />
          </InfoBlock>
        </section>
      </main>
      <Footer>
        <Statistics />
      </Footer>
    </>
  )
}

export const query = graphql`
  query WhatWeDoPageQuery {
    sanityWhatWeDoPage {
      bannerImage {
        asset {
          gatsbyImageData
        }
      }
      mainText
      subText
      bannerTextVisible

      clubModelSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        _rawContent
      }
      peaceOfMindSection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        _rawContent
      }
      growingResponsiblySection {
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        _rawContent
      }
    }
    sanityCompanyInfo {
      npaVideoLink
    }
  }
`

export default WhatWeDoPage